import React, { useMemo, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import ProjectCard from "../ProjectCard"

const Portfolio = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulProjectCategory(sort: { fields: name, order: ASC }) {
        nodes {
          name
          slug
        }
      }
      allContentfulProjects(sort: { fields: year, order: DESC }) {
        nodes {
          id
          title
          slug
          year
          categories {
            name
            slug
          }
          featureImage {
            gatsbyImageData(
              placeholder: BLURRED
              layout: CONSTRAINED
              width: 500
              aspectRatio: 1
              quality: 100
            )
          }
        }
      }
    }
  `)

  const allProjects = useMemo(
    () => data?.allContentfulProjects?.nodes ?? [],
    [data]
  )

  const topics = useMemo(() => {
    const topics = [
      { name: "All", slug: "all" },
      ...(data?.allContentfulProjectCategory?.nodes ?? []),
    ].map(topic => ({
      ...topic,
      count:
        topic.slug === "all"
          ? allProjects.length
          : allProjects.filter(
              project =>
                (project?.categories ?? []).findIndex(
                  category => category.slug === topic.slug
                ) !== -1
            ).length,
    }))
    return topics
  }, [data, allProjects])

  const [topicSlugSelected, setTopicSlugSelected] = useState("all")
  const [projects, setProjects] = useState(allProjects)

  useEffect(() => {
    setProjects(
      topicSlugSelected === "all"
        ? allProjects
        : allProjects.filter(
            project =>
              (project?.categories ?? []).findIndex(
                category => category.slug === topicSlugSelected
              ) !== -1
          )
    )
  }, [allProjects, topicSlugSelected])

  return (
    <section className="bg-black">
      <div className="flex flex-wrap justify-center p-8">
        {topics.map(({ name, slug, count }, index) => (
          <button
            className="group relative m-5 uppercase font-semibold cursor-pointer"
            key={`project-filter-${index}-${slug}`}
            onClick={() => setTopicSlugSelected(slug)}
          >
            <span
              className={`absolute -bottom-0.5 bg-[#505050] h-3 transition-all duration-500 ease-in-out group-hover:w-full ${
                topicSlugSelected === slug ? `w-full` : `w-0`
              }`}
            ></span>
            <span className="relative">{name}</span>
            <span
              className={`absolute text-xs -top-2 -right-2 font-raleway transition-opacity duration-500 ease-in-out group-hover:opacity-100 ${
                topicSlugSelected === slug ? `opacity-100` : `opacity-0`
              }`}
            >
              {count}
            </span>
          </button>
        ))}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5">
        {projects.map(({ id, title, slug, categories, featureImage }) => (
          <ProjectCard
            key={id}
            title={title}
            slug={slug}
            featureImage={featureImage}
            categories={categories || []}
          />
        ))}
      </div>
    </section>
  )
}

export default Portfolio
