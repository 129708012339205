import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import SmallBanner from "../../components/SmallBanner"
import ProjectsList from "../../components/ProjectsPage/ProjectsList"

const ProjectsPage = () => {
  return (
    <>
      <GatsbySeo title="Projects" titleTemplate="%s | BESHAUS" />
      <SmallBanner
        title="Projects"
        gatsbyImage={
          <StaticImage
            src="../../assets/images/projects-banner-background.jpg"
            className="w-full h-full"
            quality="100"
            placeholder="blurred"
            alt="portfolio banner background"
          />
        }
      />
      <ProjectsList />
    </>
  )
}

export default ProjectsPage
