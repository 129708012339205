import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ProjectCard = ({ title, slug, featureImage, categories = [] }) => {
  return (
    <Link to={`/projects/${slug.toLowerCase()}`} className="group relative">
      <div className="overflow-hidden">
        <ProjectImage title={title} featureImage={featureImage} />
      </div>
      <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center transition-opacity duration-500 ease-in-out opacity-0 group-hover:opacity-100 text-white text-center overflow-hidden">
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-80"></div>
        <div className="relative p-10">
          <h3 className="text-sm md:text-xl">{title}</h3>
          {categories.length > 0 ? (
            <div className="mt-3 flex justify-center items-center flex-wrap">
              {categories.map(({ name }, index) => (
                <span
                  key={`project-category-${index}`}
                  className="m-1 uppercase font-josefin-sans font-medium text-xs sm:text-sm tracking-wide text-beshaus-preheading-text"
                >
                  [{name}]
                </span>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </Link>
  )
}

const ProjectImage = React.memo(({ title, featureImage }) => (
  <GatsbyImage
    image={getImage(featureImage)}
    className="transition-transform duration-1000 ease-in-out transform group-hover:scale-125"
    alt={title}
  />
))

export default ProjectCard
